import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Card, CardBody } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import styles from "../../../styles.module.css";
import style from "./style.module.css";
import { ICON_NAMES } from "utils/constant";
import { TextButton } from "ui/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PageTypePath } from "utils/pages";
import { AnnualStatementGridColumnItem } from "component/pages/annualStatement/GridColumns";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { lastColWithBtn } from "ui/grid/lastColumnts";
import { AnnualStatement } from "interface/accounting";
import { addMonths } from "date-fns";
import { getAnnualStatementApi } from "api/accounting";
import { PdfType } from "utils/PdfType";

function YearlyRevenueCard() {
	const [gridData, setGridData] = useState<AnnualStatement[]>([]);

	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		getAnnualStatementApi({
			take: 3,
			skip: 0,
			fromDate: addMonths(new Date(), -1),
			toDate: new Date(),
		})
			.then((response) => {
				setGridData(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const openPdfByRow = (prop: GridCellProps) => {
		const id = `${prop.dataItem.year}-${prop.dataItem.customerBusinessRegisterNumber}`;
		window.open(
			`/${PageTypePath.Pdf}/${id}/${PdfType.AnnualStatement}`,
			"_blank"
		);
	};
	return (
		<div>
			<Card //className={style.CardItem}
				className={"home-card-item"}
			>
				<CardBody>
					<div className={`${styles.BoxHeadingMedium} ${style.CardBodyHeader}`}>
						<div>{t("AnnualReport")}</div>
						<div>
							<TextButton
								text={"Oversikt " + t("AnnualReport")}
								onClick={() => navigate(`/${PageTypePath.SalesNotesList}`)}
								iconName={ICON_NAMES.Arrow}
							/>
						</div>
					</div>
					<div>
						<Grid
							style={{ height: 180 }}
							rowHeight={38}
							rowRender={CustomRowRender}
							data={gridData}
							className={
								styles.tableColgroupColFirstChild + " custom_detail_grid"
							}
						>
							{AnnualStatementGridColumnItem().map((column, key) => (
								<GridColumn key={key} {...column} className="no-border" />
							))}
							<GridColumn
								{...lastColWithBtn({
									width: "60px",
									showDetails: false,
									// items: lastColumItems({ edit: editItem }),
									items: [
										{
											iconName: ICON_NAMES.Download,
											onclick: openPdfByRow,
											alwaysShow: true,
										},
									],
									showDelete: false,
								})}
							/>
						</Grid>
					</div>
				</CardBody>
			</Card>
		</div>
	);
}

export default YearlyRevenueCard;
