import React, { useRef, useState } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import styles from "styles.module.css";
import { ICON_NAMES } from "utils/constant";

import Filter from "./Filter";
import { useNavigate } from "react-router";
import { PageTypePath } from "utils/pages";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import { addMonths } from "date-fns";
import OverLayLoading from "ui/Loading";
import GridCustomPage from "ui/grid/GridPage";
import { useTranslation } from "react-i18next";
import { releasedPackageGridColumnItem } from "./GridColumns";
import {
	getReleasedPackagesApi,
	ReleasedPackageFilter,
} from "api/releasedPackage";
import { PdfType } from "utils/PdfType";

const pageSize = 60;

const ReleasedPackagePage: React.FC<{}> = ({}) => {
	const { t, i18n } = useTranslation();

	const router = useNavigate();

	const overlayRef = useRef<HTMLDivElement>(null);

	const [filterOptions, setFilterOptions] = useState<ReleasedPackageFilter>({
		take: pageSize,
		skip: 0,
		fromDate: addMonths(new Date(), -1),
		toDate: new Date(),
	});

	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};

	const openPdf = (props: GridCellProps) => {
		window.open(
			`/${PageTypePath.Pdf}/${props.dataItem.registrationId}/${PdfType.ReleasedPackage}`,
			"_blank"
		);
	};

	return (
		<div className={styles.PageContainer}>
			<div ref={overlayRef} style={{ display: "none" }}>
				<OverLayLoading />
			</div>
			<div className="page-header">
				<legend className={styles.BoxHeadingLarge}>
					{t("ReleasedPackages")}
				</legend>
			</div>

			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{ height: "100%" }}>
				<GridCustomPage
					dataKey="registrationId"
					gridColumns={releasedPackageGridColumnItem()}
					exportGridAsPDFRef={pdfRef}
					exportGridAsExcelRef={excelRef}
					apiMethod={getReleasedPackagesApi}
					filter={filterOptions}
					lastColWidth="100px"
					showDetailIcon={false}
					lastColBtns={[
						{
							iconName: ICON_NAMES.Download,
							alwaysShow: true,
							onclick: openPdf,
						},
					]}
				/>
			</div>
		</div>
	);
};

export default ReleasedPackagePage;
