import React, { FC, useEffect, useState } from "react";
import { PDFViewer, PDFViewerTool } from "@progress/kendo-react-pdf-viewer";
import { useParams } from "react-router";
import { getPdfApi } from "api/pdf";
import { useInfoStore } from "store/error";

const tools: PDFViewerTool[] = [
	"pager",
	"spacer",
	"zoomInOut",
	"zoom",
	"selection",
	"spacer",
	"search",
	//"open",
	"download",
	"print",
];

export const PdfViewer: FC<{}> = ({}) => {
	const { id, type } = useParams();
	const [pdfData, setPdfData] = useState<string>();
	const { setError } = useInfoStore();

	useEffect(() => {
		console.log("id", id, type);

		if (!id || !type) {
			return;
		}

		getPdfApi(id, type)
			.then((response) => {
				// console.log("pdf response", response);
				setPdfData(response);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	}, []);

	return <PDFViewer data={pdfData} style={{ height: "100%" }} tools={tools} />;
};

export default PdfViewer;
