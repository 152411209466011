import React, { useEffect, useState } from "react";
import {
	PanelBar,
	PanelBarItem,
	PanelBarUtils,
	PanelBarSelectEventArguments,
	MenuItem,
} from "@progress/kendo-react-layout";
//import { MenuPageName, PageGroup, PageTypePath } from "utils/pages";
import { PageTypePath } from "utils/pages";

import { getMenuApi } from "api/menu";

import { Font } from "utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStore, useUserStore } from "store";
import { cookieAuthApi } from "api/auth";
import { logoutApi } from "api/auth";
import { useMenuItemStore } from "store/menuItem";

const SideMenuBar: React.FC<{
	currentPage: string;
}> = ({ currentPage }) => {
	const navigate = useNavigate();
	const { user, setStore } = useUserStore();
	const { setLogout } = useAuthStore();
	const { menuItems, setMenuItem } = useMenuItemStore();
	const [selectedItem, setSelectedItem] = useState({ id: "", expandId: [""] });
	const [menuData, setMenuData] = React.useState<Array<any>>([]);

	let { path, id, action } = useParams();

	const handleOnselect = (e: PanelBarSelectEventArguments) => {
		const path = e.target.props.path;
		console.log(e);
		setSelectedItem({
			expandId: e.expandedItems,
			id: e.target.props.id as any,
		});
		if (path === PageTypePath.Home) {
			navigate(`/`);
			return;
		} else if (path === PageTypePath.Logout) {
			setLogout();
			logoutApi()
				.then(() => {
					navigate(`/login`);
				})
				.catch((error) => {
					console.log(error);
				});
			return;
		}

		if (path) {
			navigate(`/${path}`);
		}
	};

	useEffect(() => {
		if (menuItems.length < 1) {
			getMenu();
			return;
		}
		handleMenuUpdate();
	}, [path, menuItems]);

	useEffect(() => {
		cookieAuthApi()
			.then((response) => {
				setStore(response.roles, response.user);
			})
			.catch((error) => {
				navigate(`/login`);
			});
	}, []);

	const getMenu = () => {
		getMenuApi()
			.then((response) => {
				setMenuItem(
					response.map((item) => ({
						...item,
						id: item.id.toString(),
						children: item.children
							? item.children.map((child) => ({
									...child,
									id: child.id.toString(),
							  }))
							: null,
					})) as any
				);
				// try {
				// 	if (path) {
				// 		response.forEach((item) => {
				// 			let expand = false;

				// 			if (item.path === path) {
				// 				item.selected = true;
				// 			} else {
				// 				if (item.children) {
				// 					item.children.forEach((child) => {
				// 						if (child.path === path) {
				// 							child.selected = true;
				// 							expand = true;
				// 						}
				// 					});

				// 					if (expand) {
				// 						item.expanded = true;
				// 					}
				// 				}
				// 			}
				// 		});
				// 	} else {
				// 		response[0].selected = true;
				// 	}
				// } catch (error) {
				// 	console.log("error", error);
				// }

				// console.log("response", response);

				// setMenuData(response);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleMenuUpdate = () => {
		let data = menuItems;
		// data = data.map((item) => ({ ...item, id: item.id.toString() })) as any;
		try {
			if (path) {
				data.forEach((item) => {
					let expand = false;

					if (item.path === path) {
						item.selected = true;
						setSelectedItem({ id: item.id as any, expandId: [item.id] as any });
					} else {
						if (item.children) {
							item.children.forEach((child) => {
								if (child.path === path) {
									setSelectedItem({
										id: child.id as any,
										expandId: [item.id] as any,
									});

									// child.selected = true;
									// expand = true;
								}
							});

							// if (expand) {
							// 	item.expanded = true;
							// }
						}
					}
				});
			} else {
				setSelectedItem({
					id: data[0].id as any,
					expandId: [data[0].id] as any,
				});
				// data[0].selected = true;
			}
		} catch (error) {
			console.log("error", error);
		}
		setMenuData(data);
	};
	const components = PanelBarUtils.mapItemsToComponents(menuData);
	if (!user) return <div></div>;
	return (
		<PanelBar
			style={{
				top: "4px",
				right: "0px",
				backgroundColor: "transparent",
				fontFamily: Font.WorkSans,
				fontSize: "15px",
				border: "1px solid transparent",
			}}
			onSelect={handleOnselect}
			expandMode="single"
			selected={selectedItem.id}
			expanded={selectedItem.expandId}
			isControlled={true}
			keepItemsMounted={true}
			children={components}
		/>
	);
};

export default SideMenuBar;
