import { GridColumnProps } from "@progress/kendo-react-grid";
import i18n from "../../../../i18n";
import { GrideDateFormatter } from "ui/Date";
import CellPopover from "ui/grid/DescriptionCell";
import { NumericalCell, NumericalHeader } from "ui/grid/GridCommandCell";
import { CLASSNAME } from "utils/constant";

let t = i18n.t;

export const BuyerOverviewGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},

		{
			field: "salesId",
			title: t("NumberShort"),
			width: "100px",
		},
		{
			field: "salesDate",
			title: t("Date"),
			width: "120px",

			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.salesDate}
					formatType={"MM.dd.yyyy"}
				/>
			),
		},
		{
			field: "information",
			title: t("Information"),
			cell: (props) => <CellPopover {...props} />,
		},
		{
			field: "buyerName",
			title: t("BuyerName"),
			cell: (props) => <CellPopover {...props} />,
		},

		{
			field: "auctionId",
			title: t("AuctionId"),
			width: "100px",
		},
		{
			field: "salesType",
			title: t("Type"),
			width: "100px",
			headerClassName: "no-border",
		},
	];
	return gridItems;
};

export const purchaseOverviewDetailGridItems: GridColumnProps[] = [
	{
		//this will not be render
		field: "lineNumber",
		title: "Linje",
	},
	{
		field: "number",
		title: t("NumberShort"),
		width: "140px",
	},
	{ field: "description", title: t("Description") },
	{
		field: "vesselRegistrationId",
		title: t("VesselRegistrationId"),
		width: "110px",
	},
	{
		field: "vesselName",
		title: t("VesselName"),
	},

	{
		field: "netLiveWeightKg",
		title: t("Weight"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "amount",
		title: t("Amount"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kr"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		width: "40px",
		className: `${CLASSNAME.TableNumeric} no-border`,
	},
];
