
//Mappings to pages
export enum PageTypePath {
    Home = "/",

    //registration
    RegistrationForm = "ny-innmelding", 
    RegistrationList = "innmeldinger",

    //Sales notes
    SalesNotesList = "sluttsedler",
    SalesNoteForm = "rediger_sluttseddel",

    //Landing notes
    LandingNoteList = "landingssedler",
    LandingNoteForm = "rediger_landingssedler",

    // Accounting
    AccountingSettlement = "avregninger",
    AccountingAnnualReport = "aarsrapport",
    //AccountingQuota = "kvote",
    AccountingInvoice = "fakturaer",
    ReleasedPackages = "frigitte-pakker",
    //AccountingInterestNote = "rentenotaer",
    //AccountingBuyerBalance = "kjoepersaldo",

    //sales
    //MySalesStatistics = "Min_statistikk",
    //OpenSalesStatistics = "Åpen_statistikk",

    //My purchase
    PurchaseOverview = "kjopsoversikt",
    SalesConfirmations = "salgsbekreftelser",
    SalesContract = "salgskontrakter",
    PickList = "Plukkliste",

    //Pdf
    Pdf = "pdf",

    Logout = "Logout",
}

export function pageNotFound(path?: string): boolean {

    if (!path || path === "" || path === "/") {
        return true;
    }

    const _path = Object.entries(PageTypePath).find((item) => {
        return item[1].toLowerCase() === path.toLowerCase()
    })

    if (_path) {
        return true;
    }

    return false;
}