export enum PdfType {
    TemporarySalesNote = 10,
    SalesNote = 11,

    TemporaryLandingNote = 20,
    LandingNote = 21,

    SalesConfirmation = 30,
    SalesConfirmationContract = 37, 
    Invoice = 31,
    Settlement = 32,
    InterestNote = 33,
    ReleasedPackage = 34,
    YearlyRevenueReport = 35,
    AnnualStatement= 35,
    Contract = 36
}
