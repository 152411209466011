import React, { useEffect, useRef, useState } from "react";
import AccountSettingPopup from "./UserSetting";
import { Popup } from "@progress/kendo-react-popup";
import { useUserOrganizationsStore } from "store";
import { ICON_NAMES } from "utils/constant";
import UserOrganization from "./HomePageItem";
import { getUserDefaultOrganizationApi } from "api/user";
import { Button } from "@progress/kendo-react-buttons";
import { useClickOutside } from "utils/hooks";
import { OrganizationType } from "interface/user";

const HeaderItem: React.FC<{}> = ({}) => {
	const [showSetting, setShowSetting] = useState(false);
	const [showOrganizations, setShowOrganizations] = useState(false);

	const { defaultOrg, setDefault } = useUserOrganizationsStore();

	const anchor = useRef<any>(null);
	const orgRef = useRef<any>(null);
	const itemAnchor = useRef<any>(null);

	useEffect(() => {
		if (defaultOrg === null) {
			getUserDefaultOrganizationApi()
				.then((response) => {
					// console.log(response);
					setDefault(response.data);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, []);

	useClickOutside(orgRef, (node) => {
		if (node.classList.contains("k-panelbar-item-text")) {
			return;
		}
		setShowOrganizations(false);
	});

	const handleShowSetting = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setShowSetting(!showSetting);
	};
	const getIcon = (type: OrganizationType) => {
		if (type === OrganizationType.Buyer) {
			return ICON_NAMES.Buyer;
		}

		if (type === OrganizationType.Vessel) {
			return ICON_NAMES.Vessel;
		}

		if (type === OrganizationType.Terminal) {
			return ICON_NAMES.Terminal;
		}
	};
	const toggleShowOrg = () => {
		setShowOrganizations(!showOrganizations);
	};

	return (
		<div
			style={{
				display: "flex",
				height: "44px",
				paddingRight: 30,
				justifyContent: "space-between",
			}}
		>
			<div>
				{defaultOrg && (
					<div ref={orgRef}>
						<div>
							<Button
								style={{
									backgroundColor: "transparent",
									borderColor: "transparent",
									fontFamily: "Work Sans",
									fontSize: "15px",
									lineHeight: "18px",
								}}
								startIcon={
									<img
										src={`/icon/${getIcon(defaultOrg?.type)}.svg`}
										alt={getIcon(defaultOrg?.type)}
										style={{}}
									/>
								}
								className="org-name"
								// onClick={() => console.log("fofoovo")}
								onClick={toggleShowOrg}
							>
								{defaultOrg.displayName}
							</Button>
						</div>
						<div ref={itemAnchor} style={{ borderRadius: "12px" }}>
							<Popup
								// onClose={() => setShowOrganizations(!showOrganizations)}
								anchor={itemAnchor.current}
								anchorAlign={{
									horizontal: "left",
									vertical: "top",
								}}
								popupAlign={{
									horizontal: "left",
									vertical: "top",
								}}
								// offset={{
								// 	left: 1000,
								// 	top: 0,
								// }}
								show={showOrganizations}
								style={{
									marginTop: "15px",
									borderRadius: "12px !important",
									background: "none",
								}}
								popupClass={"user-setting-popup"}
							>
								<UserOrganization
									onSelect={() => setShowOrganizations(false)}
								/>
							</Popup>
						</div>
					</div>
				)}
			</div>

			<div style={{ display: "flex", gap: "10px" }}>
				{/*<div style={{ display: "flex" }}>*/}
				{/*	<span*/}
				{/*		style={{*/}
				{/*			color: "white",*/}
				{/*			backgroundColor: "#A62408",*/}
				{/*			borderRadius: "8px",*/}
				{/*			width: "26px",*/}
				{/*			height: "16px",*/}
				{/*			position: "absolute",*/}
				{/*			textAlign: "center",*/}
				{/*			paddingBottom: "4px",*/}
				{/*			marginTop: "10px",*/}
				{/*			marginLeft: "-18px",*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		4*/}
				{/*	</span>*/}
				{/*	<img*/}
				{/*		src={"/icon/notification.svg"}*/}
				{/*		alt="notification"*/}
				{/*		style={{ width: "25.6px", height: "21.2px" }}*/}
				{/*	/>*/}
				{/*</div>*/}
				<div>
					<img
						ref={anchor}
						onClick={() => setShowSetting(!showSetting)}
						src={"/icon/account.svg"}
						alt="account"
						className="account-icon"
						style={{ width: "25.6px", height: "21.2px", cursor: "pointer" }}
						onMouseOver={(e) => {
							setShowSetting(true);
						}}
					/>

					<AccountSettingPopup
						show={showSetting}
						setShow={setShowSetting}
						anchorRef={anchor.current}
					/>
				</div>
			</div>
		</div>
	);
};

export default HeaderItem;
