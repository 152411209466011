import { ApiResponse } from "interface/response"
import { AxiosApi } from "./index"
import { FilterOption } from "interface/index"
import { PurchaseOverview } from "interface/sales"


export interface PurchaseOverviewFilter extends  FilterOption{
    buyerLedgerAccountNumber? : string,
    buyerName?: string,
    buyerId?: string
}



export async function getPurchaseOverviewApi(params: PurchaseOverviewFilter) {
    const apiUri = "Sales/Overview"

    try {
        const sales = await AxiosApi.get<ApiResponse<PurchaseOverview[]>>(apiUri, {
            params: {
                ...params,
            }
        })
        return sales.data
    } catch (error) {
        throw error
    }
}

    
export async function getPurchaseOverviewLinesApi({salesId, source}:{salesId: number, source: number}) {
    const apiUri = "Sales/OverviewLines"

    try {
        const sales = await AxiosApi.get<ApiResponse<PurchaseOverview[]>>(apiUri, {
            params: {
                source, salesId
            }
        })
        return sales.data
    } catch (error) {
        throw error
    }
}
    