import {
	GridColumnProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import i18n from "../../../../i18n";
import { GrideDateFormatter } from "ui/Date";
import CellPopover from "ui/grid/DescriptionCell";
import { NumericalCell } from "ui/grid/GridCommandCell";
import { CLASSNAME } from "utils/constant";
import { FC } from "react";

let t = i18n.t;

export const pickListGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			field: "registrationId",
			title: t("RegistrationIdShort"),
			className: `no-border`,
		},
		{
			field: "packageId",
			title: "Package Id",
			className: `no-border`,
		},

		// {
		// 	field: "salesDate",
		// 	title: t("Date"),
		// 	// cell: (props) => {
		// 	// 	return (
		// 	// 		<GrideDateFormatter
		// 	// 			date={props.dataItem.salesDate}
		// 	// 			formatType={"MM.dd.yyyy"}
		// 	// 		/>
		// 	// 	);
		// 	// },
		// 	width: "150px",

		// 	cells: {
		// 		data: (props) => {
		// 			return (
		// 				<GrideDateFormatter
		// 					date={props.dataItem.salesDate}
		// 					formatType={"MM.dd.yyyy"}
		// 				/>
		// 			);
		// 		},
		// 	},
		// },
		{
			field: "productName",
			title: t("Product"),
			cells: { data: (props) => <CellPopover {...props} /> },
			className: `no-border`,
		},

		{
			field: "netLiveWeightKg",
			title: t("Weight"),
			cells: {
				data: (props) => <NumericalCell {...props} unit={"kg"} style={{}} />,

				headerCell: (props) => <NumericalHeader {...props} />,
			},
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
		},
		{
			field: "price",
			title: t("Price"),
			cells: {
				data: (props) => <NumericalCell {...props} unit={"kr"} />,

				headerCell: (props) => <NumericalHeader {...props} />,
			},

			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			// width: "150px",
		},
		{
			field: "buyerName",
			title: t("BuyerName"),
			className: `no-border`,
			cells: { data: (props) => <CellPopover {...props} /> },
		},
	];
	return gridItems;
};

export const NumericalHeader: FC<
	GridHeaderCellProps & { style?: React.CSSProperties } & any
> = (props) => {
	return (
		<th style={{ ...props.style, textAlign: "end", paddingRight: "10px" }}>
			{props.title}
		</th>
	);
};
