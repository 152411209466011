import React, { useRef, useState } from "react";

import styles from "styles.module.css";
import Filter from "./Filter";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { addMonths } from "date-fns";
import OverLayLoading from "ui/Loading";
import GridCustomPage from "ui/grid/GridPage";
import { useTranslation } from "react-i18next";
import { GridCellProps } from "@progress/kendo-react-grid";
import { PageTypePath } from "utils/pages";
import { PdfType } from "utils/PdfType";
import { ICON_NAMES } from "utils/constant";
import {
	confirmSalesConfirmationApi,
	getSalesConfirmationApi,
	getSalesConfirmationLinesApi,
	SalesConfirmationFilter,
} from "api/salesConfirmation";
import {
	salesConfirmationDetailGridItems,
	SalesConfirmationGridColumn,
} from "./GridColumns";
import CustomDetailedPage from "ui/grid/DetailPage";

const pageSize = 60;

const SalesConfirmations = () => {
	const { t } = useTranslation();

	const overlayRef = useRef<HTMLDivElement>(null);

	const gridRef = useRef<HTMLDivElement>(null);

	const [filterOptions, setFilterOptions] = useState<SalesConfirmationFilter>({
		take: pageSize,
		skip: 0,
		fromDate: addMonths(new Date(), -1),
		toDate: new Date(),
	});

	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);
	const [saleConfirmationUpdate, setSaleConfirmationUpdate] = useState(false);

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};

	const openPdf = (prop: GridCellProps) => {
		if ((prop.dataItem.salesConfirmationTypeId = 1)) {
			window.open(
				`/${PageTypePath.Pdf}/${prop.dataItem.id}/${PdfType.SalesConfirmationContract}`,
				"_blank"
			);
		} else if ((prop.dataItem.salesConfirmationTypeId = 2)) {
			window.open(
				`/${PageTypePath.Pdf}/${prop.dataItem.id}/${PdfType.SalesConfirmation}`,
				"_blank"
			);
		}
	};

	const confirmSales = (prop: GridCellProps) => {
		confirmSalesConfirmationApi({
			id: prop.dataItem.id,
			confirmationTypeId: prop.dataItem.salesConfirmationTypeId,
		})
			.then(() => {
				setSaleConfirmationUpdate(!saleConfirmationUpdate);
			})
			.catch((err) => {});
	};
	return (
		<div className={styles.PageContainer}>
			<div ref={overlayRef} style={{ display: "none" }}>
				<OverLayLoading />
			</div>
			<div className="page-header">
				<legend className={styles.BoxHeadingLarge}>
					{"salgsbekreftelser"}
				</legend>
			</div>

			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{}} ref={gridRef}>
				<GridCustomPage
					dataKey="id"
					gridColumns={SalesConfirmationGridColumn()}
					detailPage={(prop) => (
						<CustomDetailedPage
							grids={salesConfirmationDetailGridItems}
							dataApi={() =>
								getSalesConfirmationLinesApi({
									salesId: prop.dataItem.id,
									confirmationTypeId: prop.dataItem.salesConfirmationTypeId,
								})
							}
						/>
					)}
					filter={filterOptions}
					exportGridAsPDFRef={pdfRef}
					lastColWidth="140px"
					forceUpdate={saleConfirmationUpdate}
					lastColBtns={[
						{
							iconName: ICON_NAMES.Edit,
							showConditionValue: 1,
							conditionField: "status",
							onclick: confirmSales,
						},
						{
							iconName: ICON_NAMES.Confirmation,
							showConditionValue: 2,
							conditionField: "status",
							onclick: confirmSales,
						},
						{
							iconName: ICON_NAMES.Download,
							alwaysShow: true,
							onclick: openPdf,
						},
					]}
					exportGridAsExcelRef={excelRef}
					apiMethod={getSalesConfirmationApi}
				/>
			</div>
		</div>
	);
};

export default SalesConfirmations;
