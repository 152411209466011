import { Card, CardBody } from "@progress/kendo-react-layout";
import React from "react";
import { COLOR, ICON_NAMES } from "../../../utils/constant";
import CatchCard, { BuyerCard } from "./CatchDataCard";
import Notification from "./Notification";
import styles from "../../../styles.module.css";
import { TextButton } from "ui/Button";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { VesselStatistic } from "interface/statistic";
import { Loader } from "@progress/kendo-react-indicators";

function RightItems({
	title,
	iconName,
	vesselStatistic,
}: {
	title: string;
	iconName?: string;
	vesselStatistic?: VesselStatistic;
}) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "360px",
				borderRadius: "12px",
				gap: "30px",
			}}
		>
			<Card
				className={"home-card-item"}
				style={{
					borderRadius: "12px",
					flex: 1,
					// paddingLeft: "10px",
					// paddingRight: "10px",
					// paddingTop: "10px",
				}}
			>
				<CardBody>
					<div
						className={styles.BoxHeadingMedium}
						style={{ display: "flex", gap: "5px" }}
					>
						{iconName && <img src={`/icon/${iconName}.svg`} alt={iconName} />}

						<span>{title}</span>
					</div>
					{!vesselStatistic ? (
						<Loader size="large" type={"pulsing"} />
					) : (
						<>
							<CatchCard
								specieType={"Torsk"}
								catchWeight={vesselStatistic.totalLiveWeightKgCod || 0}
								totalQuota={vesselStatistic.quotaLiveWeightKgCod}
								sliderColor={COLOR.BlueLight}
							/>
							<CatchCard
								specieType={"Sei"}
								catchWeight={vesselStatistic.totalLiveWeightKgPollock || 0}
								totalQuota={vesselStatistic.quotaLiveWeightKgPollock || 0}
								sliderColor={COLOR.RedLight}
							/>
							<CatchCard
								specieType={"Hyse"}
								catchWeight={vesselStatistic.totalLiveWeightKgHaddock || 0}
								totalQuota={vesselStatistic.quotaLiveWeightKgHaddock}
								sliderColor={COLOR.YellowLight}
							/>
						</>
					)}
				</CardBody>
			</Card>

			<Card
				className={"home-card-item"}
				style={{
					borderRadius: "12px",
					flex: 1,

					// maxHeight: "100%",
					// paddingLeft: "10px",
					// paddingRight: "10px",
					// paddingTop: "20px",
				}}
			>
				<CardBody>
					<Notification />
				</CardBody>
			</Card>
		</div>
	);
}

export function BuyerRightItems({
	availableBalance,
	balance,
	availableBalancePercentage,
}: {
	availableBalance?: number;
	balance?: number;
	availableBalancePercentage?: number;
}) {
	const navigate = useNavigate();
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "360px",
				gap: "30px",
			}}
		>
			<Card
				style={{
					flex: 1,
					// paddingLeft: "10px",
					// paddingRight: "10px",
					// paddingTop: "10px",
					borderRadius: "12px",
				}}
				className={"home-card-item"}
			>
				<CardBody className="border-radius">
					<div
						className={`${styles.BoxHeadingMedium}`}
						style={{ display: "flex", justifyContent: "space-between" }}
					>
						<div>{"Min kjøpsgaranti"}</div>
						<div>
							<TextButton
								text={"Oversikt "}
								// onClick={() => navigate(`/${PageTypePath.}`)}
								onClick={() => {}}
								iconName={ICON_NAMES.Arrow}
							/>
						</div>
					</div>

					<BuyerCard
						availableBalance={availableBalance || 0}
						balance={balance || 0}
						sliderColor={COLOR.BlueLight}
						percentage={availableBalancePercentage || 0}
					/>
				</CardBody>
			</Card>

			<Card
				className={"home-card-item"}
				style={{
					flex: 1,

					maxHeight: "100%",
					// paddingLeft: "10px",
					// paddingRight: "10px",
					// paddingTop: "20px",
					borderRadius: "12px",
				}}
			>
				<CardBody>
					<Notification />
				</CardBody>
			</Card>
		</div>
	);
}

export default RightItems;
