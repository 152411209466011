import React, { useRef, useState } from "react";

import styles from "styles.module.css";

import Filter from "./Filter";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { addMonths } from "date-fns";
import OverLayLoading from "ui/Loading";
import GridCustomPage from "ui/grid/GridPage";
import { useTranslation } from "react-i18next";
import { GridCellProps } from "@progress/kendo-react-grid";
import { PageTypePath } from "utils/pages";
import { PdfType } from "utils/PdfType";
import { ICON_NAMES } from "utils/constant";
import {
	BuyerOverviewGridColumnItem,
	purchaseOverviewDetailGridItems,
} from "./utils/GridColumns";
import {
	getPurchaseOverviewApi,
	getPurchaseOverviewLinesApi,
	PurchaseOverviewFilter,
} from "api/sales";
import CustomDetailedPage from "ui/grid/DetailPage";

const pageSize = 60;

const PurchaseOverview = () => {
	const { t } = useTranslation();

	const overlayRef = useRef<HTMLDivElement>(null);

	const gridRef = useRef<HTMLDivElement>(null);
	const [filterOptions, setFilterOptions] = useState<PurchaseOverviewFilter>({
		take: pageSize,
		skip: 0,
		fromDate: addMonths(new Date(), -1),
		toDate: new Date(),
	});

	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};

	const openEdit = (prop: GridCellProps) => {
		// navigate(`/`);
		// window.open(
		// 	`/${PageTypePath.LandingNoteForm}/${prop.dataItem.noteNumber}/0`
		// );
	};

	const handleLinesPdfDown = (props: GridCellProps, id: string) => {
		if (props.dataItem.source === 2) {
			window.open(`/${PageTypePath.Pdf}/${id}/${PdfType.SalesNote}`, "_blank");
		} else if (props.dataItem.source === 3) {
			window.open(
				`/${PageTypePath.Pdf}/${id}/${PdfType.SalesConfirmation}`,
				"_blank"
			);
		} else if (props.dataItem.source === 4) {
			window.open(`/${PageTypePath.Pdf}/${id}/${PdfType.Contract}`, "_blank");
		} else if (props.dataItem.source === 5) {
			window.open(
				`/${PageTypePath.Pdf}/${id}/${PdfType.SalesConfirmationContract}`,
				"_blank"
			);
		}
	};
	return (
		<div className={styles.PageContainer}>
			<div ref={overlayRef} style={{ display: "none" }}>
				<OverLayLoading />
			</div>
			<div className="page-header">
				<legend className={styles.BoxHeadingLarge}>
					{t("PurchaseOverview")}
				</legend>
			</div>

			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{}} ref={gridRef}>
				<GridCustomPage
					dataKey="salesId"
					gridColumns={BuyerOverviewGridColumnItem()}
					filter={filterOptions}
					exportGridAsPDFRef={pdfRef}
					lastColWidth="130px"
					detailPage={(prop) => (
						<CustomDetailedPage
							grids={purchaseOverviewDetailGridItems}
							dataApi={() =>
								getPurchaseOverviewLinesApi({
									salesId: prop.dataItem.salesId,
									source: prop.dataItem.source,
								})
							}
							lastColBtns={[
								{
									notEqualToValue: 1,
									conditionField: "source",
									iconName: ICON_NAMES.Download,
									onclick: (props) =>
										handleLinesPdfDown(props, prop.dataItem.salesId),
								},
							]}
						/>
					)}
					lastColBtns={[
						{
							iconName: ICON_NAMES.Edit,
							conditionField: "source",
							showConditionValue: 0,
							onclick: openEdit,
						},
						// {
						// 	iconName: ICON_NAMES.Download,
						// 	alwaysShow: true,
						// 	onclick: openPdf,
						// },
					]}
					exportGridAsExcelRef={excelRef}
					apiMethod={getPurchaseOverviewApi}
				/>
			</div>
		</div>
	);
};

export default PurchaseOverview;
