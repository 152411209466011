import { FC, useState } from "react";
import CustomButton from "ui/Button";
import { ICON_NAMES } from "utils/constant";
import { useTranslation } from "react-i18next";
import CustomDropDownList from "ui/dropDown";
import { PurchaseOverviewFilter } from "api/sales";
import { getBuyersFilterApi } from "api/common";

interface CustomProps {
	pageSize: number;
	setFilterOptions: (options: PurchaseOverviewFilter) => void;
	filterOptions: PurchaseOverviewFilter;
	exportAsPDF: () => void;
	exportAsExcel: () => void;
}
const Filter: FC<CustomProps> = ({
	setFilterOptions,
	filterOptions,
	exportAsPDF,
	...props
}) => {
	const { t } = useTranslation();

	return (
		<div className="filter-container">
			<div className="filter-items">
				<div style={{ width: "170px" }}>
					<CustomDropDownList
						onSelect={(buyer) =>
							setFilterOptions({
								...filterOptions,
								buyerId: buyer.id,
								buyerName: buyer.name,
								buyerLedgerAccountNumber: buyer.id,
								take: props.pageSize,
								skip: 0,
							})
						}
						data={[]}
						value={{
							id: filterOptions.buyerId || null,
							name: filterOptions.buyerName || t("ChooseBuyer"),
						}}
						dataItemKey="id"
						textField="name"
						label={t("Buyer")}
						defaultItem={{ id: null, name: t("All") }}
						className="height-inherit"
						filterable={true}
						serverFilter={true}
						serverFilterApi={getBuyersFilterApi}
					/>
				</div>
			</div>

			<div className="filter-end-btn">
				<CustomButton
					label={"PDF"}
					onClick={() => exportAsPDF()}
					iconName={ICON_NAMES.Download}
				/>
				<CustomButton
					label={"Excel"}
					onClick={() => props.exportAsExcel()}
					iconName={ICON_NAMES.Download}
				/>
			</div>
		</div>
	);
};

export default Filter;
