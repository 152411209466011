import { AxiosApi } from "api"
import { FilterOption } from "interface"
import { ReleasePackage } from "interface/package"
import { ApiResponse } from "interface/response"

export interface ReleasedPackageFilter extends  Omit<FilterOption, "vesselRegistrationNumber" >{
    vesselRegistrationId? : string,
    buyerLedgerAccountNumber? : string,
    registrationId?: string 
    vesselId?: string,
    vesselName?: string
    buyerName?: string,
    buyerId?: string
}

export async function getReleasedPackagesApi( filter:ReleasedPackageFilter ) {
    const apiUri = "ReleasedPackage"
    try {
        const packages = await AxiosApi.get<ApiResponse<ReleasePackage[]>>(apiUri, {
            params: filter
        })
        return packages.data
    } catch (error) {
        throw error
    } 
}
