import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Card, CardBody } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import styles from "../../../styles.module.css";
import style from "./style.module.css";
import { ICON_NAMES } from "utils/constant";
import { TextButton } from "ui/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PageTypePath } from "utils/pages";
import { getSettlementsApi } from "api/accounting";
import { Settlement } from "interface/accounting";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { SettlementGridColumnItem } from "component/pages/settlement/utilities/GridColumns";
import { lastColWithBtn } from "ui/grid/lastColumnts";
import { addMonths } from "date-fns";
import { PdfType } from "utils/PdfType";

function AccountingSettlementCard() {
	const [gridData, setGridData] = useState<Settlement[]>();
	const { t } = useTranslation();
	const navigate = useNavigate();
	useEffect(() => {
		getSettlementsApi({
			take: 3,
			skip: 0,
			fromDate: addMonths(new Date(), -1),
			toDate: new Date(),
			// buyerId: defaultOrg?.ledgerAccountNumber as any,
		})
			.then((response) => {
				setGridData(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const openPdfByRow = (prop: GridCellProps) => {
		window.open(
			`/${PageTypePath.Pdf}/${prop.dataItem.id}/${PdfType.Settlement}`,
			"_blank"
		);
	};

	return (
		<div>
			<Card //className={style.CardItem}
				className={"home-card-item"}
			>
				<CardBody>
					<div className={`${styles.BoxHeadingMedium} ${style.CardBodyHeader}`}>
						<div>{t("LastSettlements")}</div>
						<div>
							<TextButton
								text={t("OverviewSettlements")}
								onClick={() => navigate(`/${PageTypePath.SalesNotesList}`)}
								iconName={ICON_NAMES.Arrow}
							/>
						</div>
					</div>
					<div>
						<Grid
							style={{ height: 180 }}
							rowHeight={38}
							rowRender={CustomRowRender}
							data={gridData}
							className={
								styles.tableColgroupColFirstChild + " custom_detail_grid"
							}
						>
							{SettlementGridColumnItem().map((column, key) => (
								<GridColumn key={key} {...column} className="no-border" />
							))}
							<GridColumn
								{...lastColWithBtn({
									width: "60px",
									showDetails: false,
									// items: lastColumItems({ edit: editItem }),
									items: [
										{
											iconName: ICON_NAMES.Download,
											onclick: openPdfByRow,
											alwaysShow: true,
										},
									],
									showDelete: false,
								})}
							/>
						</Grid>
					</div>
				</CardBody>
			</Card>
		</div>
	);
}

export default AccountingSettlementCard;
