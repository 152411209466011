import { ApiResponse } from "interface/response"
import { AxiosApi } from "./index";
import { PickupList } from "interface/pickList";
import { FilterOption } from "interface";

export interface PickListFilter extends FilterOption{
    buyerLedgerNumber?: string
    buyerId?: string,
    buyerName?: string,
}
export async function getPickListApi(filter: PickListFilter) {
    const apiUri = "PickList/";

    try {
        const menuResponse = await AxiosApi.get<ApiResponse<PickupList[]>>(apiUri, {
            params: filter
        })
        return menuResponse.data
    } catch (error) {
        throw error
    }
}
