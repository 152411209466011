import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
	Grid,
	GridCellProps,
	GridColumn,
	GridColumnProps,
	GridDataStateChangeEvent,
	GridDetailRowProps,
	GridEvent,
	GridExpandChangeEvent,
	GridGroupChangeEvent,
	GridRowClickEvent,
	GridRowProps,
	GridToolbar,
} from "@progress/kendo-react-grid";
import styles from "styles.module.css";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import "./styles.css";
import { Skeleton } from "@progress/kendo-react-indicators";
import { lastColWithBtn } from "./lastColumnts";
import Info from "ui/Info";
import OverLayLoading from "ui/Loading";
import { useInfoStore } from "store/error";
import { CustomError } from "interface";
import {
	setGroupIds,
	getGroupIds,
	setExpandedState,
} from "@progress/kendo-react-data-tools";
import { groupBy, GroupDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { ApiResponse } from "interface/response";
import { formatNorwegianNumber } from "utils/helper";
import { CLASSNAME } from "utils/constant";
import { t } from "i18next";
import { GrideDateFormatter } from "ui/Date";

const unUsedHeight = 370; // manually adjusted height
interface GridPageProps {
	filter?: any;
	dataKey?: string;
	isExpandable?: boolean;
	hideScroll?: boolean;
	forceUpdate?: boolean;
	className?: string;
	exportGridAsPDFRef?: React.RefObject<GridPDFExport>;
	exportGridAsExcelRef?: React.RefObject<ExcelExport>;
	gridColumns: GridColumnProps[];
	itemAboveHeight?: number;
	groups: GroupDescriptor[];
	lastColBtns?: {
		onclick?: (props: GridCellProps) => void;
		iconName: string;
		showConditionValue?: any;
		conditionField?: string;
		alwaysShow?: boolean;
	}[];
	lastColWidth?: string;
	showDetailIcon?: boolean;
	detailPage?: React.ComponentType<GridDetailRowProps>;
	apiMethod: (filter: any) => Promise<any>;
	deleteItem?: {
		api: (id: any) => Promise<any>;
		info: { title: string; description: string };
		showConditionValue?: any;
		conditionField?: string;
	};
	gridHeight?: number;
	gridMaxHeight?: number;
	handelShowRowBgColor?: (rowData: GridRowProps) => string;
}

const pageSize = 50;
const expectedTotal = 700;

const GridCustomGroupPage: FC<GridPageProps> = ({
	dataKey = "id",
	filter = Object(),
	exportGridAsExcelRef,
	exportGridAsPDFRef,
	showDetailIcon = true,
	lastColWidth = "50px",
	isExpandable = true,
	hideScroll = false,
	gridHeight = 900,
	itemAboveHeight = 0,
	handelShowRowBgColor,
	forceUpdate,
	...props
}) => {
	const [gridData, setGridData] = useState<any[]>([]);
	const [groupedData, setGroupedData] = useState<any[]>([]);
	const [collapsedState, setCollapsedState] = useState<string[]>([]);

	const requestInProgress = React.useRef(false);
	const deletingItemRef = React.useRef<any>();
	const [showDelete, setShowDelete] = useState(false);
	const [pageHeight, setPageHeight] = useState(window.innerHeight);

	const overlayRef = useRef<HTMLDivElement>(null);

	const gridRef = useRef<HTMLDivElement>(null);

	const pageRef = useRef({
		dataFinished: false,
		resetData: false,
		nextSkip: 0,
		expectedTotal: expectedTotal,
	});
	const { setError } = useInfoStore();

	const detailRef = useRef({ isOpen: false, index: 0 });
	// useEffect(() => {
	// 	const updateHeight = () => setPageHeight(window.innerHeight);
	// 	window.addEventListener("resize", updateHeight);

	// 	// Cleanup listener on unmount
	// 	return () => window.removeEventListener("resize", updateHeight);
	// }, []);

	const requestData = (skip: number, take: number) => {
		if (requestInProgress.current || pageRef.current.dataFinished) return;
		requestInProgress.current = true;

		props
			.apiMethod({
				...filter,
				skip,
				take,
			})
			.then((response: ApiResponse<any[]>) => {
				pageRef.current.expectedTotal = response.totalCount;
				if (response.data === null) {
					response.data = [];
				}
				if (response.data.length === 0 && !pageRef.current.resetData) {
					pageRef.current.dataFinished = true;
				} else {
					pageRef.current.nextSkip = skip + take;
					if (pageRef.current.resetData) {
						setGridData(response.data);
					} else {
						setGridData([...gridData, ...response.data]);
					}
					pageRef.current.resetData = false;
				}
				requestInProgress.current = false;
			})
			.catch((error) => {
				requestInProgress.current = false;
				if (error.response?.data) {
					if (error.response.data?.errors) {
						setError(new CustomError(error.response?.data.title));
					} else {
						setError(new CustomError(error.response?.data.returnText));
					}
				} else {
					setError(new CustomError(error.message));
				}
			})
			.finally(() => {
				hideOverlay();
			});
	};
	useEffect(() => {
		const updateHeight = () => setPageHeight(window.innerHeight);
		window.addEventListener("resize", updateHeight);

		// Cleanup listener on unmount
		return () => window.removeEventListener("resize", updateHeight);
	}, []);

	useEffect(() => {
		showOverlay();
		pageRef.current.resetData = true;
		pageRef.current.dataFinished = false;
		requestData(0, pageSize);
	}, [filter, forceUpdate]);

	useEffect(() => {
		setGroupedData(groupData(gridData, props.groups));
	}, [gridData, props.groups]);

	const showOverlay = () => {
		if (overlayRef.current) {
			overlayRef.current.style.display = "block";
		}
	};

	const hideOverlay = () => {
		if (overlayRef.current) {
			overlayRef.current.style.display = "none";
		}
	};

	// const openCloseDetail = (dataItem: any) => {
	// 	const data = dataItem;
	// 	const newData = gridData.map((item, index) => {
	// 		if (data[dataKey] === item[dataKey]) {
	// 			if (!item.expanded) {
	// 				detailRef.current = {
	// 					index,
	// 					isOpen: false,
	// 				};

	// 				detailRef.current = {
	// 					index,
	// 					isOpen: !item.expanded,
	// 				};
	// 			}
	// 			item.expanded = !item.expanded;
	// 		} else {
	// 			item.expanded = false;
	// 		}
	// 		return item;
	// 	});
	// 	setGridData(newData);
	// };

	// const expandDetail = (e: GridRowClickEvent) => {
	// 	if (!showDetailIcon) {
	// 		return;
	// 	}
	// 	openCloseDetail(e.dataItem);
	// };

	// const handleOpenDetail = (e: GridCellProps) => {
	// 	if (!showDetailIcon) {
	// 		return;
	// 	}
	// 	openCloseDetail(e.dataItem);
	// };

	// const handleFetchData = (e: HTMLDivElement) => {
	// 	if (e.scrollHeight - e.scrollTop < e.offsetHeight * 2) {
	// 		requestData(pageRef.current.nextSkip, pageSize);
	// 	}
	// };

	// const handleOnScroll = (e: GridEvent) => {
	// 	const el = e.nativeEvent.srcElement as HTMLDivElement;
	// 	// handleFetchData(el);
	// };

	const initDelete = (pros: GridCellProps) => {
		deletingItemRef.current = pros.dataItem;
		setShowDelete(true);
	};

	// const handleDelete = () => {
	// 	if (!deletingItemRef.current || !props.deleteItem) {
	// 		return;
	// 	}
	// 	const id = deletingItemRef.current[dataKey];
	// 	props.deleteItem
	// 		.api(id)
	// 		.then(() => {
	// 			setGridData(gridData.filter((item) => item[dataKey] !== id));
	// 			setShowDelete(false);
	// 		})
	// 		.catch((error: any) => {
	// 			console.log(error);
	// 		});
	// };
	// let deleteItem: any;
	// if (props.deleteItem) {
	// 	deleteItem = {
	// 		btn: initDelete,
	// 		showConditionValue: props.deleteItem.showConditionValue,
	// 		conditionField: props.deleteItem.conditionField,
	// 	};
	// }

	// const handleGroupChange = useCallback((e: GridGroupChangeEvent) => {
	// 	setGroupedData(groupData(gridData, props.groups));
	// }, []);

	// const onExpandChange = React.useCallback(
	// 	(event: GridExpandChangeEvent) => {
	// 		const item = event.dataItem;

	// 		if (item.groupId) {
	// 			const collapsedIds = !event.value
	// 				? [...collapsedState, item.groupId]
	// 				: collapsedState.filter((groupId) => groupId !== item.groupId);
	// 			setCollapsedState(collapsedIds);
	// 		}
	// 	},
	// 	[collapsedState]
	// );

	// const onGroupsToggle = useCallback(() => {
	// 	const data = groupData(gridData, props.groups);

	// 	setCollapsedState(collapsedState.length ? [] : getGroupIds({ data }));
	// }, [collapsedState, gridData, props.groups]);

	const currentHeight = pageHeight - unUsedHeight - itemAboveHeight;
	return (
		<div style={{ height: "calc(100% - 96px)" }}>
			<div ref={overlayRef} style={{ display: "none" }}>
				<OverLayLoading />
			</div>
			<div style={{ marginTop: 30 }} ref={gridRef}>
				<ExcelExport data={gridData || []} ref={exportGridAsExcelRef}>
					<Grid
						style={{
							maxHeight: "100%",
							height: currentHeight,
							paddingBottom: "30px",
							overflow: "auto",
						}}
						rowHeight={34}
						expandField="expanded"
						data={groupedData}
						pageSize={pageSize}
						rowRender={(row, props) => CustomRowRender(row, props, () => "")}
						// onScroll={handleOnScroll}
						cellRender={cellRender}
						groupable={{ footer: "visible", enabled: false }}
						// onRowClick={expandDetail}
						group={props.groups}
						// onGroupChange={handleGroupChange}
						// onExpandChange={onExpandChange}
						className={props.className}
					>
						{props.gridColumns.map((column, key) => (
							<GridColumn key={key} {...column} />
						))}
					</Grid>
				</ExcelExport>
				{/* {props.deleteItem && (
					<Info
						close={() => setShowDelete(!showDelete)}
						title={props.deleteItem.info.title}
						show={showDelete}
						description={props.deleteItem.info.description}
						actions={[
							{ text: "Cancel", btn: () => setShowDelete(!showDelete) },
							{
								text: "Confirm",
								btn: handleDelete,
								bgColor: "red",
								textColor: "white",
							},
						]}
					/>
				)} */}
			</div>
			<GridPDFExport ref={exportGridAsPDFRef}>
				{/* <Grid data={gridData} pageable={true}>
					{props.gridColumns.map((column, key) => (
						<GridColumn key={key} {...column} />
					))}
				</Grid> */}
			</GridPDFExport>
		</div>
	);
};

export default GridCustomGroupPage;

const loadingCell = (tdElement: any, props: any) => {
	const field = props.field || "";
	if (props.dataItem[field] === undefined) {
		return (
			<td>
				<Skeleton
					shape={"text"}
					style={{
						width: "100%",
					}}
				/>
			</td>
		);
	}
	return tdElement;
};

const groupData = (data: any[], group: GroupDescriptor[]) => {
	const newDataState = groupBy(data, group);
	setGroupIds({ data: newDataState, group });

	return newDataState;
};

const cellRender: any = (
	tdElement: React.ReactElement<HTMLTableCellElement>,
	cellProps: GridCellProps
) => {
	if (
		cellProps.rowType === "groupFooter" &&
		cellProps.dataItem.field !== "group1"
	) {
		const formatDecimal = 2;
		if (cellProps.field === "netLiveWeightKg") {
			return (
				<td
					aria-colindex={cellProps.columnIndex}
					role={"gridcell"}
					className={`${tdElement.props.className}  ${CLASSNAME.TableNumeric} no-border td-bold`}
					// style={{ backgroundColor: "white" }}
				>
					<div style={{ marginTop: "-20px" }}>
						{`${formatNorwegianNumber(
							cellProps.dataItem.aggregates.netLiveWeightKg.sum,
							formatDecimal,
							formatDecimal
						)} kg`}{" "}
					</div>

					{/* {cellProps.dataItem.aggregates.netLiveWeightKg.sum} kr */}
				</td>
			);
		} else if (cellProps.field === "registrationId") {
			return (
				<td
					aria-colindex={cellProps.columnIndex}
					role={"gridcell"}
					className="td-bold"
					style={{}}
				>
					<div style={{ height: "60px" }}>
						<span style={{ marginTop: "20px", display: "block" }}>
							{" "}
							Sum {cellProps.dataItem.items[0].vesselName}{" "}
						</span>
					</div>

					{/* {cellProps.dataItem.items[0].vesselRegistrationId} */}
				</td>
			);
		}
	}

	if (
		cellProps.rowType === "groupHeader" &&
		cellProps.dataItem.field === "group1" &&
		cellProps.columnIndex === 0
	) {
		return (
			<td
				aria-colindex={cellProps.columnIndex}
				role={"gridcell"}
				className="td-bold-group"
				colSpan={4}
			>
				{cellProps.dataItem.value}
			</td>
		);
	}
	if (
		cellProps.rowType === "groupHeader" &&
		cellProps.dataItem.field === "group2" &&
		cellProps.columnIndex === 1
	) {
		return (
			<td
				aria-colindex={cellProps.columnIndex}
				role={"gridcell"}
				className="td-bold-group"
				colSpan={4}
			>
				{cellProps.dataItem.value}
			</td>
		);
	}
	return tdElement;
};
