import React, { FC, useEffect } from "react";
import {
	Field,
	FieldRenderProps,
	FieldWrapper,
} from "@progress/kendo-react-form";
import {
	FormDropDownList,
	FormDatePicker,
	FormInput,
	FormDropDownListWithSearch,
	ItemLabel,
} from "ui/Topography";
import { useState } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import {
	getCatchAreaApi,
	getCountriesApi,
	getFishingGearApi,
	getFoodSafetyApprovalNumberApi,
	getQuotaTypeApi,
	getSearchUsageApi,
	getTerminalsApi,
} from "api/common";
import { DROPDOWN_ITEMS, getLandingTypeField } from "utils/dropdown/items";
import { yesOrNoField } from "utils/dropdown/helper";
import { DATE_FORMAT } from "utils/constant";
import { t } from "i18next";
import {
	CatchArea,
	Country,
	DropDownItem,
	FishingGear,
} from "interface/common";
import { useInfoStore } from "store/error";
import { LandingNoteHead } from "interface/landingNote";
import { saveLandingNoteHead } from "api/landingNote";
import {
	requirementFields,
	handleRequirementCheck,
	areAllRequirementsMet,
} from "../utils";

const FormStepOne: FC<{
	form: LandingNoteHead;
	setForm: (form: LandingNoteHead) => void;
	setAllRequirementsMet: (allMet: boolean) => void;
	showRequirementMetError: boolean;
}> = ({ form, setForm, setAllRequirementsMet, showRequirementMetError }) => {
	const [quotaType, setQuotaType] = useState<DropDownItem[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [foodSafetyApproveNumber, setFoodSafetyApproveNumber] = useState<
		DropDownItem[]
	>([]);
	const [requirementMet, setRequirementMet] = useState(requirementFields);
	const { setError } = useInfoStore();

	useEffect(() => {
		setRequirementMet(handleRequirementCheck(form));
		setAllRequirementsMet(areAllRequirementsMet(handleRequirementCheck(form)));
	}, [form, setAllRequirementsMet]);

	const getQuotaTypeData = () => {
		getSearchUsageApi()
			.then((data) => {})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
		getQuotaTypeApi()
			.then((data) => {
				setQuotaType(data);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const getCountries = () => {
		getCountriesApi()
			.then((data) => {
				setCountries(data);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const getFoodSafetyApprovalNumber = () => {
		getFoodSafetyApprovalNumberApi(form.buyerFdirId)
			.then((data) => {
				setFoodSafetyApproveNumber(data);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const saveForm = (form: LandingNoteHead) => {
		setForm(form);
		form.noteStatus = 2;

		saveLandingNoteHead(form)
			.then((response) => {
				// console.log(response);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};
	return (
		<>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Fangstdagbok"}
					name={"Fangstdagbok"}
					label={"Fangstdagbok*"}
					// placeholder={"example"}
					currentValue={form?.diaryNumber}
					type={"number"}
					component={FormInput}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.diaryNumber
					)}
					onChange={(e: any) => {
						saveForm({ ...form, diaryNumber: e.value });
					}}
				/>
				<Field
					id={"Tur_nummer*"}
					name={"Tur nummer*"}
					label={"Tur nummer*"}
					// placeholder={"emt"}
					component={FormInput}
					currentValue={form?.tripNumber}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.tripNumber
					)}
					type={"number"}
					onChange={(e: any) => {
						saveForm({ ...form, tripNumber: e.value });
					}}
				/>

				<Field
					id={"ERS-meldingsnr"}
					name={"ERS-meldingsnr"}
					label={"ERS-meldingsnr. (POR)*"}
					textField={"field"}
					dataItemKey={"value"}
					// isValid={handleIsValid(
					// 	showRequirementMetError,
					// 	requirementMet.tripNumber
					// )}
					// currentValue={data.buyer}
					data={DROPDOWN_ITEMS.yesOrNo}
					//	onChange={(e: any) => setForm({ ...form, diaryNumber: e.value })}
					component={FormDropDownList}
				/>
				<Field
					id={"FørsteFangstdato*"}
					name={"FørsteFangstdato"}
					label={"ForsteFangstdato*"}
					currentValue={form.firstCatchDate}
					placeholder=""
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.firstCatchDate
					)}
					format={DATE_FORMAT}
					onChange={(e: any) => {
						saveForm({ ...form, firstCatchDate: formatDate(e.value) });
					}}
					component={FormDatePicker}
				/>

				<Field
					id={"Siste_Fangstdato*"}
					name={"Siste_Fangstdato*"}
					label={"Siste Fangstdato*"}
					currentValue={form.lastCatchDate}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.lastCatchDate
					)}
					onChange={(e: any) => {
						saveForm({ ...form, lastCatchDate: formatDate(e.value) });
					}}
					placeholder=""
					format={DATE_FORMAT}
					component={FormDatePicker}
				/>

				<Field
					id={"Fangst"}
					name={"Fangstår"}
					label={"Fangstår*"}
					placeholder={""}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.catchYear
					)}
					currentValue={form.catchYear}
					component={FormInput}
					type={"number"}
					onChange={(e: any) => {
						saveForm({ ...form, catchYear: e.value });
					}}
				/>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Mannskap"}
					name={"Mannskap"}
					label={"Mannskap (antall)*"}
					placeholder={""}
					component={FormInput}
					currentValue={form.crewSize}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.crewSize
					)}
					type={"number"}
					onChange={(e: any) => saveForm({ ...form, crewSize: e.value })}
				/>
				<Field
					id={"Herav_norsk_mannskap*"}
					name={"Herav_norsk_mannskap*"}
					label={"Herav norsk mannskap*"}
					placeholder={""}
					component={FormInput}
					currentValue={form.norwegianCrewSize}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.norwegianCrewSize
					)}
					type={"number"}
					onChange={(e: any) =>
						saveForm({ ...form, norwegianCrewSize: e.value })
					}
				/>

				<Field
					id={"Norsk_skipper*"}
					name={"Norsk_skipper*"}
					label={"Norsk skipper ?*"}
					data={DROPDOWN_ITEMS.yesOrNo}
					textField={"field"}
					dataItemKey={"value"}
					// defaultItem={{ field: "Choose", value: "" }}
					currentValue={{
						value: form?.vesselOfficersNorwegian,
						field: yesOrNoField(form?.vesselOfficersNorwegian),
					}}
					showErrorMessage={
						!handleIsValid(
							showRequirementMetError,
							requirementMet.vesselOfficersNorwegian
						)
					}
					onSelect={(data: { field: string; value: number }) =>
						saveForm({ ...form, vesselOfficersNorwegian: data.value })
					}
					component={FormDropDownList}
				/>
				<Field
					id={"Avgangsdatodato"}
					name={"Avgangsdatodato"}
					label={"Avgangsdatodato*"}
					currentValue={form.departureDate}
					placeholder={""}
					format={DATE_FORMAT}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.departureDate
					)}
					onChange={(e) => {
						saveForm({ ...form, departureDate: formatDate(e.value) });
					}}
					component={FormDatePicker}
				/>

				<Field
					id={"Ankomstdato*"}
					name={"Ankomstdato"}
					label={"Ankomstdato*"}
					currentValue={form.arrivalDate}
					value={form.arrivalDate}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.arrivalDate
					)}
					placeholder={""}
					format={DATE_FORMAT}
					onChange={(e) => {
						saveForm({ ...form, arrivalDate: formatDate(e.value) });
					}}
					component={FormDatePicker}
				/>

				<Field
					id={"Fangstdøgn"}
					name={"Fangstdøgn"}
					label={"Fangstdøgn"}
					placeholder={""}
					currentValue={form.daysFished}
					component={FormInput}
					type={"number"}
					onChange={(e) => {
						saveForm({ ...form, daysFished: e.value });
					}}
				/>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr ",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Redskap"}
					name={"Redskap"}
					label={t("FishingGear")}
					value={t("ChooseFishingGear")}
					filterable={true}
					textField={"displayName"}
					dataItemKey={"id"}
					filterApi={getFishingGearApi}
					defaultItem={{
						displayName: t("ChooseFishingGear"),
						id: null,
					}}
					currentValue={{
						id: form.fishingGearId,
						displayName: form.fishingGear,
					}}
					onSelect={(fishingGear: FishingGear) => {
						saveForm({
							...form,
							fishingGearId: fishingGear.id,
							fishingGear: fishingGear.name,
						});
					}}
					component={FormDropDownListWithSearch}
				/>
				<Field
					name={"Fangstfelt"}
					//label={"Fangstfelt"}
					label={t("CatchArea")}
					filterable={true}
					textField={"displayName"}
					dataItemKey={"id"}
					filterApi={getCatchAreaApi}
					defaultItem={{
						displayName: t("ChooseCatchArea"),
						id: null,
					}}
					currentValue={{
						id: form.catchField,
						displayName: `${form.catchAreaZone} | ${form.catchAreaQuotaZone} | ${form.catchArea}`,
					}}
					// className="error"
					onSelect={(catchArea: CatchArea) => {
						saveForm({
							...form,
							catchField: catchArea.code,
							catchAreaZone: catchArea.zone,
							catchAreaQuotaZone: catchArea.groundFDZone,
							catchArea: catchArea.name,
						});
					}}
					component={FormDropDownListWithSearch}
				/>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr ",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Kvotetype*"}
					name={"Kvotetype*"}
					label={"Kvotetype*"}
					textField={"name"}
					dataItemKey={"code"}
					data={quotaType}
					currentValue={{ code: form.quotaTypeCode, name: form.quotaType }}
					loadDataOnClicked={getQuotaTypeData}
					onChange={(e: { value: { code: string; name: string } }) => {
						saveForm({
							...form,
							quotaTypeCode: e.value.code,
							quotaType: e.value.name,
						});
					}}
					showErrorMessage={
						!handleIsValid(
							showRequirementMetError,
							requirementMet.quotaTypeCode
						)
					}
					component={FormDropDownList}
				/>

				<Field
					id={"Kvotenasjon"}
					name={"Kvotenasjon"}
					label={"Kvotenasjon*"}
					placeholder={""}
					component={FormInput}
					currentValue={form.quotaNationCode}
					onChange={(e: any) => {
						saveForm({ ...form, quotaNationCode: e.value });
					}}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.quotaNationCode
					)}
				/>

				<Field
					id={"Landingsnasjon"}
					name={"Landingsnasjon"}
					label={"Landingsnasjon*"}
					textField={"englishDescription"}
					dataItemKey={"code"}
					loadDataOnClicked={getCountries}
					data={countries}
					currentValue={{
						code: form.landingNationCode,
						englishDescription: form.landingNation,
					}}
					onChange={(e: { value: Country }) => {
						saveForm({
							...form,
							landingNationCode: e.value.code,
							landingNation: e.value.description,
						});
					}}
					showErrorMessage={
						!handleIsValid(
							showRequirementMetError,
							requirementMet.landingNationCode
						)
					}
					component={FormDropDownList}
				/>
				<Field
					id={"Landingstype"}
					name={"Landingstype"}
					label={"Landingstype*"}
					data={DROPDOWN_ITEMS.landingType}
					textField={"field"}
					dataItemKey={"value"}
					showErrorMessage={
						!handleIsValid(
							showRequirementMetError,
							requirementMet.partialLandingId
						)
					}
					currentValue={getLandingTypeField(form.partialLandingId as any)}
					component={FormDropDownList}
					onChange={(e: { value: { field: string; value: number } }) => {
						saveForm({ ...form, partialLandingId: e.value.value });
					}}
				/>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr ",
					justifyContent: "space-between",
					gap: "20px",
					marginBottom: "25px",
				}}
			>
				<Field
					id={"Forrige sted"}
					name={"Forrige sted"}
					label={"Forrige sted"}
					filterable={true}
					textField={"name"}
					dataItemKey={"code"}
					filterApi={getTerminalsApi}
					defaultItem={{
						name: "Choose",
						code: null,
					}}
					currentValue={{
						code: form.previousReceivingFacility,
						name: form.previousReceivingFacility,
					}}
					// className="error"
					onSelect={(data: DropDownItem) => {
						saveForm({ ...form, previousReceivingFacility: data.name });
					}}
					component={FormDropDownListWithSearch}
				/>
				<Field
					id={"Neste sted"}
					name={"Neste sted"}
					label={"Neste sted"}
					filterable={true}
					textField={"name"}
					dataItemKey={"code"}
					filterApi={getTerminalsApi}
					defaultItem={{
						name: "Choose",
						code: null,
					}}
					currentValue={{
						code: form.nextReceivingFacility,
						name: form.nextReceivingFacility,
					}}
					// className="error"
					onSelect={(data: DropDownItem) => {
						saveForm({ ...form, nextReceivingFacility: data.code });
					}}
					component={FormDropDownListWithSearch}
				/>
				<Field
					id={"Skipper"}
					name={"Skipper"}
					label={"Skipper*"}
					placeholder={""}
					component={FormInput}
					currentValue={form.vesselCaptainsName}
					onChange={(e: any) => {
						setForm({ ...form, vesselCaptainsName: e.value });
						//saveForm({ ...form, vesselCaptainsName: e.value })
					}}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.vesselCaptainsName
					)}
					saveItem={(e: any) => {
						saveForm(form);
					}}
				/>
				<Field
					id={"Godkjenningsnummer"}
					name={"Godkjenningsnummer"}
					label={"Godkjenningsnummer*"}
					data={foodSafetyApproveNumber}
					textField={"name"}
					dataItemKey={"code"}
					loadDataOnClicked={getFoodSafetyApprovalNumber}
					currentValue={{
						code: form.productionFacilityId,
						name: form.productionFacilityId,
					}}
					onSelect={(data: DropDownItem) => {
						saveForm({ ...form, productionFacilityId: data.code as any });
					}}
					isValid={handleIsValid(
						showRequirementMetError,
						requirementMet.productionFacilityId
					)}
					component={ComboBoxItem}
				/>
			</div>
		</>
	);
};

export default FormStepOne;

const ComboBoxItem: FC<FieldRenderProps> = ({
	setValueS,
	currentValue,
	onChange,
	...props
}) => {
	const [value, setValue] = useState({
		code: currentValue.code,
		name: currentValue.name,
	});

	useEffect(() => {
		if (currentValue) {
			setValue({
				code: currentValue.code || "",
				name: currentValue.name || "",
			});
		}
	}, [currentValue]);
	const handleOnChange = (event: any) => {
		const ev = event.nativeEvent;
		if (!ev || ev.code === "Enter" || ev.type === "focusout") {
			props.onSelect(value);
			return;
		}
		props.onSelect(event.value);
	};
	const focusedItemIndex = (data: any[], inputText: any, textField: any) => {
		if (inputText !== value.code || inputText !== value.name) {
			setValue({ code: inputText, name: inputText });
		}
		let text = inputText.toLowerCase();
		return data.findIndex((item: any) =>
			String(textField ? item[textField] : item)
				.toLowerCase()
				.includes(text)
		);
	};
	// <ComboBox focusedItemIndex={focusedItemIndex} />;
	return (
		<FieldWrapper style={props.wrapperStyle}>
			<ItemLabel id={props.id} text={props.label} />

			<div>
				<ComboBox
					data={props.data}
					value={value}
					onChange={handleOnChange}
					onBlur={handleOnChange}
					valid={props.isValid}
					allowCustom={true}
					placeholder="Please select ..."
					onOpen={props.loadDataOnClicked}
					textField={props.textField}
					dataItemKey={props.dataItemKey}
					focusedItemIndex={focusedItemIndex}
					// {...props}
				/>
			</div>
		</FieldWrapper>
	);
};

const formatDate = (date: Date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");

	// return `${day}.${month}.${year}`;
	return `${year}-${month}-${day}`;
};

const handleIsValid = (showError: boolean, requirement: boolean) => {
	if (!showError) return !showError;
	return requirement;
};
