import React, { FC, useEffect, useRef, useState } from "react";
import {
	Grid,
	GridCellProps,
	GridColumn,
	GridColumnProps,
	GridDetailRowProps,
	GridEvent,
	GridRowClickEvent,
	GridRowProps,
} from "@progress/kendo-react-grid";
import styles from "styles.module.css";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import "./styles.css";
import { Skeleton } from "@progress/kendo-react-indicators";
import { lastColWithBtn } from "./lastColumnts";
import Info from "ui/Info";
import OverLayLoading from "ui/Loading";
import { useInfoStore } from "store/error";
import { CustomError } from "interface";

const unUsedHeight = 370; // manually adjusted height
interface GridPageProps {
	filter?: any;
	dataKey?: string;
	isExpandable?: boolean;
	hideScroll?: boolean;
	forceUpdate?: boolean;
	exportGridAsPDFRef?: React.RefObject<GridPDFExport>;
	exportGridAsExcelRef?: React.RefObject<ExcelExport>;
	gridColumns: GridColumnProps[];
	itemAboveHeight?: number;
	lastColBtns?: {
		onclick?: (props: GridCellProps) => void;
		iconName: string;
		showConditionValue?: any;
		conditionField?: string;
		alwaysShow?: boolean;
	}[];
	lastColWidth?: string;
	showDetailIcon?: boolean;
	detailPage?: React.ComponentType<GridDetailRowProps>;
	apiMethod: (filter: any) => Promise<any>;
	deleteItem?: {
		api: (id: any) => Promise<any>;
		info: { title: string; description: string };
		showConditionValue?: any;
		conditionField?: string;
	};
	gridHeight?: number;
	gridMaxHeight?: number;
	handelShowRowBgColor?: (rowData: GridRowProps) => string;
}

const pageSize = 60;
const expectedTotal = 700;

const GridCustomPage: FC<GridPageProps> = ({
	dataKey = "id",
	filter = Object(),
	exportGridAsExcelRef,
	exportGridAsPDFRef,
	showDetailIcon = true,
	lastColWidth = "50px",
	isExpandable = true,
	hideScroll = false,
	gridHeight = 900,
	itemAboveHeight = 0,
	handelShowRowBgColor,
	forceUpdate,
	...props
}) => {
	const [gridData, setGridData] = useState<any[]>([]);
	const requestInProgress = React.useRef(false);
	const deletingItemRef = React.useRef<any>();
	const [showDelete, setShowDelete] = useState(false);
	const [pageHeight, setPageHeight] = useState(window.innerHeight);

	const overlayRef = useRef<HTMLDivElement>(null);

	const gridRef = useRef<HTMLDivElement>(null);

	const pageRef = useRef({
		dataFinished: false,
		resetData: false,
		nextSkip: 0,
		expectedTotal: expectedTotal,
	});
	const { setError } = useInfoStore();

	const detailRef = useRef({ isOpen: false, index: 0 });
	useEffect(() => {
		const updateHeight = () => setPageHeight(window.innerHeight);
		window.addEventListener("resize", updateHeight);

		// Cleanup listener on unmount
		return () => window.removeEventListener("resize", updateHeight);
	}, []);

	const requestData = (skip: number, take: number) => {
		if (requestInProgress.current || pageRef.current.dataFinished) return;
		requestInProgress.current = true;

		props
			.apiMethod({
				...filter,
				skip,
				take,
			})
			.then((response) => {
				pageRef.current.expectedTotal = response.totalCount;
				if (response.data === null) {
					response.data = [];
				}
				if (response.data.length === 0 && !pageRef.current.resetData) {
					pageRef.current.dataFinished = true;
				} else {
					pageRef.current.nextSkip = skip + take;
					if (pageRef.current.resetData) {
						setGridData([...response.data]);
					} else {
						setGridData([...gridData, ...response.data]);
					}
					pageRef.current.resetData = false;
				}
				requestInProgress.current = false;
			})
			.catch((error) => {
				requestInProgress.current = false;
				if (error.response?.data) {
					if (error.response.data?.errors) {
						setError(new CustomError(error.response?.data.title));
					} else {
						setError(new CustomError(error.response?.data.returnText));
					}
				} else {
					setError(new CustomError(error.message));
				}
			})
			.finally(() => {
				hideOverlay();
			});
	};

	useEffect(() => {
		showOverlay();
		pageRef.current.resetData = true;
		pageRef.current.dataFinished = false;
		requestData(0, pageSize);
	}, [filter, forceUpdate]);

	const showOverlay = () => {
		if (overlayRef.current) {
			overlayRef.current.style.display = "block";
		}
	};

	const hideOverlay = () => {
		if (overlayRef.current) {
			overlayRef.current.style.display = "none";
		}
	};

	const openCloseDetail = (dataItem: any) => {
		const data = dataItem;
		const newData = gridData.map((item, index) => {
			if (data[dataKey] === item[dataKey]) {
				if (!item.expanded) {
					detailRef.current = {
						index,
						isOpen: false,
					};

					detailRef.current = {
						index,
						isOpen: !item.expanded,
					};
				}
				item.expanded = !item.expanded;
			} else {
				item.expanded = false;
			}
			return item;
		});
		setGridData(newData);
	};

	const expandDetail = (e: GridRowClickEvent) => {
		if (!showDetailIcon) {
			return;
		}
		openCloseDetail(e.dataItem);
	};

	const handleOpenDetail = (e: GridCellProps) => {
		if (!showDetailIcon) {
			return;
		}
		openCloseDetail(e.dataItem);
	};

	const handleFetchData = (e: HTMLDivElement) => {
		if (e.scrollHeight - e.scrollTop < e.offsetHeight * 2) {
			requestData(pageRef.current.nextSkip, pageSize);
		}
	};

	const handleOnScroll = (e: GridEvent) => {
		const el = e.nativeEvent.srcElement as HTMLDivElement;
		handleFetchData(el);
	};

	const initDelete = (pros: GridCellProps) => {
		deletingItemRef.current = pros.dataItem;
		setShowDelete(true);
	};

	const handleDelete = () => {
		if (!deletingItemRef.current || !props.deleteItem) {
			return;
		}
		const id = deletingItemRef.current[dataKey];
		props.deleteItem
			.api(id)
			.then(() => {
				setGridData(gridData.filter((item) => item[dataKey] !== id));
				setShowDelete(false);
			})
			.catch((error: any) => {
				console.log(error);
			});
	};
	let deleteItem: any;
	if (props.deleteItem) {
		deleteItem = {
			btn: initDelete,
			showConditionValue: props.deleteItem.showConditionValue,
			conditionField: props.deleteItem.conditionField,
		};
	}
	const currentHeight = pageHeight - unUsedHeight - itemAboveHeight;
	return (
		<div style={{ height: "calc(100% - 96px)" }}>
			<div ref={overlayRef} style={{ display: "none" }}>
				<OverLayLoading />
			</div>
			<div style={{ marginTop: 30 }} ref={gridRef}>
				<ExcelExport data={gridData || []} ref={exportGridAsExcelRef}>
					<Grid
						style={{
							maxHeight: "100%",
							height: currentHeight,
							paddingBottom: "30px",
							overflow: "auto",
						}}
						rowHeight={34}
						rowRender={(row, props) =>
							CustomRowRender(row, props, handelShowRowBgColor)
						}
						expandField={isExpandable ? "expanded" : ""}
						data={gridData || []}
						// data={gridData.slice(0, gridData.length)}
						pageSize={pageSize}
						onScroll={handleOnScroll}
						cellRender={loadingCell}
						detail={props.detailPage}
						editField="inEdit"
						className={
							styles.tableColgroupColFirstChild +
							`${hideScroll ? styles.HideScroll : ""}`
						}
						onRowClick={expandDetail}
					>
						{props.gridColumns.map((column, key) => (
							<GridColumn key={key} {...column} className="no-border" />
						))}
						<GridColumn
							{...lastColWithBtn({
								width: lastColWidth,
								showDetails: showDetailIcon,
								openDetail: handleOpenDetail,
								items: props.lastColBtns,
								showDelete: props.deleteItem ? true : false,
								deleteItem,
							})}
						/>
					</Grid>
				</ExcelExport>
				{props.deleteItem && (
					<Info
						close={() => setShowDelete(!showDelete)}
						title={props.deleteItem.info.title}
						show={showDelete}
						description={props.deleteItem.info.description}
						actions={[
							{ text: "Cancel", btn: () => setShowDelete(!showDelete) },
							{
								text: "Confirm",
								btn: handleDelete,
								bgColor: "red",
								textColor: "white",
							},
						]}
					/>
				)}
			</div>
			<GridPDFExport ref={exportGridAsPDFRef}>
				<Grid data={gridData} pageable={true}>
					{props.gridColumns.map((column, key) => (
						<GridColumn key={key} {...column} />
					))}
				</Grid>
			</GridPDFExport>
		</div>
	);
};

export default GridCustomPage;

const loadingCell = (tdElement: any, props: any) => {
	const field = props.field || "";
	if (props.dataItem[field] === undefined) {
		return (
			<td>
				<Skeleton
					shape={"text"}
					style={{
						width: "100%",
					}}
				/>
			</td>
		);
	}
	return tdElement;
};
