import { GridColumnProps } from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
//import styles from "styles.module.css";
import i18n from "../../../i18n";
import { NumericalCell, NumericalHeader } from "ui/grid/GridCommandCell";
import CellPopover from "ui/grid/DescriptionCell";

let t = i18n.t;

export const releasedPackageGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},
		// {
		// 	field: "",
		// 	title: "",
		// 	width: "30px",
		// 	headerClassName: "no-border",
		// 	cell: (props) => <DotCell value={props.dataItem.status} />,
		// },
		{
			field: "registrationId",
			title: "Innm.id",
			// width: "100px",
			headerClassName: "no-border",
		},
		{
			field: "packageId",
			title: "PackageId ",
			//width: "150px",
			headerClassName: "no-border",
		},

		{
			field: "confirmedTime",
			title: t("ConfirmTime"),
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.confirmedTime}
					formatType={"dd.MM.yyyy"}
				/>
			),
			// width: "110px",
		},
		{
			field: "vesselRegistrationId",
			title: t("VesselRegistrationId"),
		},
		{
			field: "vesselName",
			title: t("Vessel"),
			cell: (props) => <CellPopover {...props} />,
		},

		{
			field: "buyerLedgerAccountNumber",
			title: t("Buyer"),
			cell: (props) => <CellPopover {...props} />,
		},

		{
			field: "buyerName",
			title: t("BuyerName"),
			cell: (props) => <CellPopover {...props} />,
		},

		{
			field: "registeredTime",
			title: "Innm.Dato",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.registeredTime}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},

		{ field: "salesType", title: t("Type") },

		{
			field: "liveWeightKg",
			title: t("Weight"),
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "amount",
			title: t("Value"),
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};
