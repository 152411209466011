import React, { useRef, useState } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import styles from "styles.module.css";
import { TextButton } from "ui/Button";
import { ICON_NAMES } from "utils/constant";
import {
	deleteRegistrationApi,
	getCatchRegistrationLineApi,
	getCatchRegistrationListApi,
	RegistrationListParams,
} from "api/registration";
import {
	gridColumnItemsHandler,
	lastColumItems,
	registrationDetailGridItems,
} from "./utils/GridColumns";
import Filter from "./utils/Filter";
import { useNavigate } from "react-router";
import { PageTypePath } from "utils/pages";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import "./styles.css";
import { addMonths } from "date-fns";
import OverLayLoading from "ui/Loading";
import GridCustomPage from "ui/grid/GridPage";
import { useTranslation } from "react-i18next";
import CustomDetailedPage from "ui/grid/DetailPage";

const pageSize = 60;

const RegistrationList: React.FC<{}> = ({}) => {
	const { t, i18n } = useTranslation();

	const router = useNavigate();

	const overlayRef = useRef<HTMLDivElement>(null);

	const [filterOptions, setFilterOptions] = useState<RegistrationListParams>({
		take: pageSize,
		skip: 0,
		fromDate: addMonths(new Date(), -1),
		toDate: new Date(),
	});

	const navigate = useNavigate();
	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};

	const editItem = (row: GridCellProps) => {
		let step = 0;

		if (row.dataItem.noteType === 0 && row.dataItem.numberOfLines > 0) {
			step = 1;
		}
		router(
			`/${PageTypePath.RegistrationForm}/${row.dataItem.registrationId}/${step}`,
			{
				state: {
					id: row.dataItem.registrationId,
					step: step,
				},
			}
		);
	};

	return (
		<div className={styles.PageContainer}>
			<div ref={overlayRef} style={{ display: "none" }}>
				<OverLayLoading />
			</div>
			<div className="page-header">
				<legend className={styles.BoxHeadingLarge}>
					{t("RegistrationList")}
				</legend>
				<legend>
					<TextButton
						text={t("NewRegistration")}
						onClick={() => navigate(`/${PageTypePath.RegistrationForm}`)}
						iconName={ICON_NAMES.Plus}
					/>
				</legend>
			</div>

			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{ height: "100%" }}>
				<GridCustomPage
					dataKey="registrationId"
					gridColumns={gridColumnItemsHandler()}
					// detailPage={DetailedPage}
					detailPage={(prop) => (
						<CustomDetailedPage
							grids={registrationDetailGridItems}
							dataApi={() =>
								getCatchRegistrationLineApi(
									prop.dataItem.registrationId,
									prop.dataItem.status
								)
							}
						/>
					)}
					exportGridAsPDFRef={pdfRef}
					exportGridAsExcelRef={excelRef}
					apiMethod={getCatchRegistrationListApi}
					filter={filterOptions}
					lastColWidth="160px"
					deleteItem={{
						api: deleteRegistrationApi,
						info: {
							title: t("DeleteRegistration"),
							description: t("DeleteRegistrationConfirmation"),
						},
						showConditionValue: 0,
						conditionField: "status",
					}}
					lastColBtns={lastColumItems({ edit: editItem })}
				/>
			</div>
		</div>
	);
};

export default RegistrationList;
