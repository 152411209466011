import React, { useRef, useState } from "react";

import styles from "styles.module.css";

import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { addMonths } from "date-fns";
import OverLayLoading from "ui/Loading";
import { useTranslation } from "react-i18next";
import { pickListGridColumnItem } from "./utils/GridColumns";
import GridCustomGroupPage from "ui/grid/GridGroupPage";
import Filter from "./Filter";
import { getPickListApi, PickListFilter } from "api/pickList";
import style from "./style.module.css";

const pageSize = 60;

const PickListPage = () => {
	const { t } = useTranslation();

	const overlayRef = useRef<HTMLDivElement>(null);

	const gridRef = useRef<HTMLDivElement>(null);
	const [filterOptions, setFilterOptions] = useState<PickListFilter>({
		take: pageSize,
		skip: 0,
		fromDate: addMonths(new Date(), -1),
		toDate: new Date(),
	});

	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};

	return (
		<div className={styles.PageContainer}>
			<div ref={overlayRef} style={{ display: "none" }}>
				<OverLayLoading />
			</div>
			<div className="page-header">
				<legend className={styles.BoxHeadingLarge}>{t("PickList")}</legend>
			</div>

			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{}} ref={gridRef}>
				<GridCustomGroupPage
					dataKey="registrationId"
					gridColumns={pickListGridColumnItem()}
					filter={filterOptions}
					exportGridAsPDFRef={pdfRef}
					exportGridAsExcelRef={excelRef}
					showDetailIcon={false}
					apiMethod={getPickListApi}
					className={"pick-list-group-grid"}
					groups={[
						{
							field: "group1",
							// aggregates: [
							// 	{ field: "netLiveWeightKg", aggregate: "sum" },
							// ],
						},
						{
							field: "group2",
							aggregates: [{ field: "netLiveWeightKg", aggregate: "sum" }],
						},
					]}
				/>
			</div>
		</div>
	);
};

export default PickListPage;
