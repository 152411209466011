import React, { useEffect, useRef } from "react";
import { Slider } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { Font } from "utils/constant";
import styles from "styles.module.css";
import { formatNorwegianNumber } from "utils/helper";

const CatchNotificationCard: React.FC<{
	specieType: string;
	catchWeight: number;
	totalQuota: number;
	sliderColor: string;
}> = ({
	specieType,
	catchWeight,

	totalQuota,
	sliderColor,
}) => {
	const { t } = useTranslation();

	const sliderRef = useRef<HTMLElement>();

	let percentValue = parseFloat(((catchWeight / totalQuota) * 100).toFixed(2));

	const remainingQuota = totalQuota - catchWeight;

	useEffect(() => {
		if (sliderRef.current) {
			const slider = (sliderRef.current as any)?._sliderTrack as
				| HTMLElement
				| undefined;

			if (slider) {
				const track = slider.querySelector(
					".k-slider-selection"
				) as HTMLElement;
				track.style.backgroundColor = sliderColor; // Set color based on .....
				track.style.height = "7px";
			}
		}
	}, []);

	return (
		<div style={{ paddingTop: "10px" }}>
			<div
				style={{ display: "flex", justifyContent: "space-between", height: 33 }}
			>
				<span
					style={{
						height: "fit-content",
						display: "block",
						marginTop: "10px",
						paddingLeft: "6px",
					}}
					className="fontRobot16"
				>
					{" "}
					{specieType}
				</span>
				<span
					style={{
						justifyContent: "space-between",
						fontFamily: Font.Roboto,
						fontStyle: "italic",
						fontSize: "28px",
						display: "block",
					}}
				>
					{percentValue}%
				</span>
			</div>
			<div>
				<Slider min={0} max={100} value={percentValue} ref={sliderRef} />
			</div>
			<div className={styles.BoxHeadingSmall}>
				<div>
					<span> {t("MyCatch") + ":"}</span>
					<span> {formatNorwegianNumber(catchWeight)} kg</span>
				</div>
				<div>
					<span style={{ opacity: 0.5 }}>{t("RestQuota") + ":"}</span>
					<span> {formatNorwegianNumber(remainingQuota)} kg</span>
				</div>
			</div>
		</div>
	);
};

export const BuyerCard: React.FC<{
	availableBalance: number;
	balance: number;
	percentage: number;
	sliderColor: string;
}> = ({ availableBalance, balance, sliderColor, percentage }) => {
	const { t } = useTranslation();

	const sliderRef = useRef<HTMLElement>();

	useEffect(() => {
		if (sliderRef.current) {
			const slider = (sliderRef.current as any)?._sliderTrack as
				| HTMLElement
				| undefined;

			if (slider) {
				const track = slider.querySelector(
					".k-slider-selection"
				) as HTMLElement;
				track.style.backgroundColor = sliderColor; // Set color based on .....
				track.style.height = "7px";
			}
		}
	}, []);

	return (
		<div style={{ paddingTop: "10px" }}>
			<div
				style={{ display: "flex", justifyContent: "space-between", height: 33 }}
			>
				<span
					style={{
						justifyContent: "space-between",
						fontFamily: Font.Roboto,
						fontStyle: "italic",
						fontSize: "28px",
						display: "block",
					}}
				>
					{percentage}%
				</span>
			</div>
			<div>
				<Slider
					min={0}
					max={100}
					value={percentage}
					ref={sliderRef}
					style={{ marginLeft: "-2px" }}
				/>
			</div>
			<div className={styles.BoxHeadingSmall}>
				<div>
					<span> {"Disponibel: "}</span>
					<span
						style={{
							fontSize: "16px",
							fontWeight: "bold",
							fontStyle: "italic",
						}}
					>
						{formatNorwegianNumber(availableBalance, 2, 2)}
					</span>
					<span> kr</span>
				</div>
				<div>
					<span style={{}}>{" Bokført saldo: "}</span>
					<span> {formatNorwegianNumber(balance, 2, 2)} kr</span>
				</div>
			</div>
		</div>
	);
};

export default CatchNotificationCard;
