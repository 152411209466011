
import { UserMenuItem } from 'interface/userMenuItem';
import { create } from 'zustand';


interface UserMenuItemStore  {
   menuItems: UserMenuItem[]
    setMenuItem: (items: UserMenuItem[]) => void;
}

export const useMenuItemStore= create<UserMenuItemStore>()((set) => ({
   menuItems: [],
   setMenuItem: (menuItems: UserMenuItem[]) => set({ menuItems })
}));




