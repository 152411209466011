import {
	Grid,
	GridCellProps,
	GridColumn,
	GridColumnProps,
} from "@progress/kendo-react-grid";
import { COLOR } from "utils/constant";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfoStore } from "store/error";
import { CustomError } from "interface";
import { lastColWithBtn } from "./lastColumnts";

interface DetailedPagePros {
	grids: GridColumnProps[];
	dataApi: () => Promise<any>;
	lastColBtns?: {
		onclick?: (props: GridCellProps) => void;
		iconName: string;
		showConditionValue?: any;
		conditionField?: string;
		alwaysShow?: boolean;
		notEqualToValue?: any;
	}[];
	lastColWidth?: string;
	// rowDetails: GridDetailRowProps;
}

const CustomDetailedPage: FC<DetailedPagePros> = ({
	grids,
	dataApi,
	lastColBtns,
	lastColWidth,
}) => {
	const { t } = useTranslation();

	const [data, setData] = useState<any[]>([]);
	const { setError } = useInfoStore();

	useEffect(() => {
		dataApi()
			.then((data) => {
				setData(data.data);
			})
			.catch((err) => {
				const errorTitle = "Error in Purchase overview";

				if (err?.response?.data) {
					setError(new CustomError(err.response?.data.returnText, errorTitle));
				} else {
					setError(new CustomError(err.message, errorTitle));
				}
				console.log(err);
			});
	}, []);

	return (
		<div>
			<Grid
				style={{
					backgroundColor: COLOR.GridOpenedBody,
					height: " fit-content",
				}}
				rowRender={CustomRowRender}
				rowHeight={30}
				data={data}
				className="custom_detail_grid"
			>
				{grids.map((column, key) => (
					<GridColumn key={key} {...column} className="no-border" />
				))}
				{lastColBtns && (
					<GridColumn
						{...lastColWithBtn({
							width: lastColWidth,
							showDetails: false,
							items: lastColBtns,
							showDelete: false,
						})}
					/>
				)}
			</Grid>
		</div>
	);
};

export default CustomDetailedPage;
